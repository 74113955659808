/* eslint-disable no-undef */
import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { Grid, Typography, List, ListItem } from "@mui/material";
import { Link } from "react-router-dom";
// import { toast } from "react-hot-toast";

// import { toggleSlider } from "../Header/toggle";
import useStyles from "./Footer.styles";
import { getCryptoCurrencyPriceStart } from "../../redux-saga/redux/casino/lobby";
import { getCMSDataStart } from "../../redux-saga/redux/cms";
import //toggleChatBox,
// toggleDepositWithdraw,
// toggleGamesSearchBox,
"../../redux-saga/redux/settings/themeSettings";
// import { ChatIcon, MenuIcon, PromoIcon, Searchicon } from "./icon";
// import { cashAppCloseNotificationPopup } from "../../redux-saga/redux/CashApp";

const Footer = () => {
  const classes = useStyles();
  // const location = useLocation();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const {
  //   sidebarOpen,
  //   isChatBoxOpen,
  //   isGamesSearchBoxOpen,
  //   isDepositWithdrawOpen,
  // } = useSelector((state) => state.themeSettings);
  //const [launcherOpacity, setLauncherOpacity] = useState(false);
  // const { isLoggedIn } = useSelector((state) => state.authentication);
  // const { crptoCurrencyPrice, loadCrypto } = useSelector(
  //   (state) => state.casinoLobby
  // );
  // const handleDepositWithdrawOpen = () => {
  //   dispatch(cashAppCloseNotificationPopup());
  //   if (isLoggedIn) dispatch(toggleDepositWithdraw(!isDepositWithdrawOpen));
  //   else toast.error("Please loggin to do deposit");
  // };
  useEffect(() => {
    dispatch(getCryptoCurrencyPriceStart());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getCMSDataStart());
  }, [dispatch]);

  // const handleChatBox = () => {
  //   sessionStorage.setItem("ZD-widgetOpen", false);
  //   sessionStorage.setItem("ZD-sendApiBlips", false);
  //   zE("messenger:set", "locale", "en");
  //   zE("messenger", "show");
  //   zE("messenger", "open");
  //   setLauncherOpacity(true);
  // };

  // const handleSearchBox = () => {
  //   dispatch(toggleGamesSearchBox(!isGamesSearchBoxOpen));
  // };
  // useEffect(() => {
  //   const handleClose = () => {
  //     zE("messenger", "hide");
  //     setLauncherOpacity(false);
  //   };

  //   // Initialize the messenger widget
  //   zE("messenger", "hide");

  //   // Set up the event listener for closing the widget
  //   zE("messenger:on", "close", handleClose);

  //   // Clean up event listener on component unmount
  //   return () => {
  //     zE("messenger:on", "close", () => {});
  //   };
  // }, []);
  // useEffect(() => {
  //   if (launcherOpacity) {
  //     const intervalId = setInterval(() => {
  //       const iframe = window.document.querySelector(
  //         "iframe[title='Messaging window']"
  //       );

  //       if (iframe) {
  //         iframe.style.borderRadius = "0";
  //         const iframeWindow = iframe.contentWindow;
  //         const iframeDocument =
  //           iframe.contentDocument || iframeWindow.document;
  //         const iframeBody1 = iframeDocument.querySelector(".xMqsn");
  //         iframeBody1?.remove();
  //         if (!iframeBody1) {
  //           return;
  //         }
  //         iframeBody1.style.opacity = "0";
  //         iframeBody1.style.visibility = "hidden";
  //         iframeBody1.style.display = "none";
  //         // iframe.style.visibility = "visible";
  //         clearInterval(intervalId);
  //       }
  //     }, 100);
  //   }
  // }, [launcherOpacity]);
  return (
    <>
      <div className={classes.footerWrapper}>
        <Grid className={classes.footerWrap}>
          <Grid container spacing={2} className={classes.footerflex}>
            <Grid xs={12} item sm={12} md={12} lg={12} xl={12}>
              <Grid className={classes.footrAboutWrap}>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/"
                  className={classes.footerLogo}
                >
                  <span className={classes.logo}>
                    <img src="/images/updateimg/logo-svg.svg" alt="logo" />
                  </span>
                  <span className={classes.mobLogo}>
                    <img src="/images/updateimg/logo-svg.svg" alt="logo-mob" />
                  </span>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/*
        <Grid className={classes.footerCryptoLogo}>
          <List>
            <ListItem>
              <img src="/images/crypto-2.svg" alt="crypto icon" />
            </ListItem>

            <ListItem>
              <img src="/images/crypto-3.svg" alt="crypto icon" />
            </ListItem>

            <ListItem>
              <img src="/images/crypto-4.png" alt="crypto icon" />
            </ListItem>

            <ListItem>
              <img src="/images/crypto-5.svg" alt="crypto icon" />
            </ListItem>

            <ListItem>
              <img src="/images/crypto-6.svg" alt="crypto icon" />
            </ListItem>

            <ListItem>
              <img src="/images/crypto-7.svg" alt="crypto icon" />
            </ListItem>

            <ListItem>
              <img src="/images/crypto-1.svg" alt="crypto icon" />
            </ListItem>

            <ListItem>
              <img src="/images/crypto-8.svg" alt="crypto icon" />
            </ListItem>
          </List>
        </Grid>
        */}
        <Grid className={classes.footerCryptoLogo}>
          <List>
            <ListItem>
              <Link
                to="/pdf/FortunaRush-Term-of-Uses-Jan-2025.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="footerLinkMenu"
              >
                Terms of Use
              </Link>
            </ListItem>

            <ListItem>
              <Link
                to="/pdf/FortunaRush-Privacy-Policy-Jan-2025.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="footerLinkMenu"
              >
                Privacy Policy
              </Link>
            </ListItem>

            <ListItem>
              <Link
                to="/pdf/FortunaRush-AML-Policy-Jan-2025.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="footerLinkMenu"
              >
                AML/CTF Policy
              </Link>
            </ListItem>

            <ListItem>
              <Link
                to="/pdf/FortunaRush-Promo-Rules-Jan-2025.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="footerLinkMenu"
              >
                Promo Rules
              </Link>
            </ListItem>
          </List>
        </Grid>

        {/* <Box className={classes.currencyWrapper}>
          <div className={classes.currencyDetail}>
            <p>
              <img src="/images/fc-1.svg" alt="bitcoin" />
              <span>
                1 BTC <small>=</small>
              </span>
            </p>
            <p>
              {(!loadCrypto && `$${crptoCurrencyPrice[0]?.current_price}`) ||
                "00.00"}
            </p>
          </div>

          <div className={classes.currencyDetail}>
            <p>
              <img src="/images/fc-2.svg" alt="ltc" />
              <span>
                1 LTC <small>=</small>
              </span>
            </p>
            <p>
              {(!loadCrypto && `$${crptoCurrencyPrice[3]?.current_price}`) ||
                "00.00"}
            </p>
          </div>

          <div className={classes.currencyDetail}>
            <p>
              <img src="/images/fc-3.svg" alt="trx" />
              <span>
                1 TRX <small>=</small>
              </span>
            </p>
            <p>
              {(!loadCrypto && `$${crptoCurrencyPrice[2]?.current_price}`) ||
                "00.00"}
            </p>
          </div>

          <div className={classes.currencyDetail}>
            <p>
              <img src="/images/fc-4.svg" alt="eth" />
              <span>
                1 ETH <small>=</small>
              </span>
            </p>
            <p>
              {(!loadCrypto && `$${crptoCurrencyPrice[1]?.current_price}`) ||
                "00.00"}
            </p>
          </div>
        </Box> */}

        <Grid className={classes.copyRight}>
          <Typography>
            Copyright (C) 2025 FortunaRush.com. All Rights Reserved.
          </Typography>
        </Grid>
      </div>

      {/* <Grid className={`${classes.mobiFooter} gameFooterHide`}>
        <ul>
          <li className={`ffff ${sidebarOpen && classes.actives}`}>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                toggleSlider(dispatch, sidebarOpen);
              }}
            >
              <Typography>
                <MenuIcon />
                <span>Menu</span>
              </Typography>
            </Link>
          </li>
          <li className={isGamesSearchBoxOpen && classes.actives}>
            <Link to="/" onClick={handleSearchBox}>
              <Typography>
                <Searchicon />
                <span>Search</span>
              </Typography>
            </Link>
          </li>
          <li
            className={classes.active}
          >
            <Link onClick={handleDepositWithdrawOpen}>
              <Typography>
                <img src="/images/wallet.svg" alt="footer-icon" />
              </Typography>
            </Link>
          </li>
          <li>
            <Link
              to="/promotions"
              className={
                matchPath("/promotions", location?.pathname || "zlehods") &&
                !isChatBoxOpen
                  ? classes.actives
                  : undefined
              }
            >
              <Typography>
                <PromoIcon />
                <span>Promo</span>
              </Typography>
            </Link>
          </li>
          <li className={isChatBoxOpen && classes.actives}>
            <Link onClick={handleChatBox}>
              <Typography>
                <ChatIcon />
                <span>Support</span>
              </Typography>
            </Link>
          </li>
        </ul>
      </Grid> */}
    </>
  );
};

export default Footer;
