/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Grid,
  Typography,
  // TextField,
  // InputAdornment,
  debounce,
  Button,
  // List,
  // ListItem,
  // Box,
  LinearProgress,
  // List,
  // ListItem,
} from "@mui/material";
import {
  useParams,
  useLocation,
  matchPath,
  useNavigate,
  // useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
// import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
// import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
// import ReactSelect from "react-select";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { isMobile } from "react-device-detect";

import useStyles from "./ViewAll.styles";
import {
  getProvidersStart,
  getGamesStart,
  getSubCategoryStart,
} from "../../../redux-saga/redux/casino/lobby";
import Game from "../Game";
import { formatCategoryName } from "../../../utils/helper";
import Provider from "../../Casino/Provider";
// import LiveTable from "../../../components/LiveTable";
//import { sidebarListItems } from "../../../components/PageLayout/constants";
import NewGameHeader from "./new_game_header";

const ViewAll = () => {
  const { pathname } = useLocation();
  const isAllgame = matchPath("/all-games/*", pathname || "zlehods");
  const navigate = useNavigate();
  const { userId, selectedWallet } = useSelector(
    (state) => state.authentication
  );
  // const initialLoad = useRef(0);
  const params = useParams();
  const dispatch = useDispatch();
  const { provider, category, name } = params;
  const [searchInput, setSearchInput] = useState("");
  const { subCategory } = useSelector((state) => state.casinoLobby);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProvider, setSelectedProvider] = useState({
    value: 0,
    label: "Providers",
  });
  useEffect(() => {
    setSearchInput("");
    setSelectedCategory("");
    setSelectedProvider({
      value: 0,
      label: "Providers",
    });
  }, [pathname]);

  //const { darkMode } = useSelector((state) => state.themeSettings);
  const [selectedSort, setSelectedSort] = useState(0);
  // const [providerList, setProviderList] = useState([]);
  const [gameListProgress, setGameListProgress] = useState(0);
  const classes = useStyles({ gameProgressWidth: gameListProgress });
  const offset = useRef(1);
  const { games, loading, gamesCount, moreGamesLoading } = useSelector(
    (state) => state.casinoLobby
  );
  useEffect(() => {
    if (games?.length !== 0 && gamesCount) {
      const progress = (games?.length / gamesCount) * 100;
      setGameListProgress(progress);
    }
  }, [games]);
  const subCategoryFiltered = subCategory;
  const handleChangeSearch = (event) => {
    setSearchInput(event.target.value);
    searchGames(event.target.value);
  };

  // useEffect(() => {
  //   if (providers?.length !== 0) {
  //     const filetereProviders = providers.filter(
  //       (e) => e.name !== "Blue Whale"
  //     );
  //     setProviderList(
  //       filetereProviders?.map((s) => {
  //         return {
  //           label: s.name,
  //           value: s.masterCasinoProviderId,
  //         };
  //       })
  //     );
  //   }
  // }, [providers]);

  //uncomment for the new game header
  const handleChangeCategory = ({ catId, name }) => {
    offset.current = 1;
    setSelectedCategory("");
    setSearchInput("");
    setSelectedSort(0);
    setSelectedProvider({ value: 0, label: "Providers" });
    navigate(`/view-all/false/${catId}/${name}`);
  };
  // const handleChangeCategoryMobile = (e) => {
  //   offset.current = 1;
  //   setSelectedCategory("");
  //   setSearchInput("");
  //   setSelectedSort(0);
  //   setSelectedProvider({ value: 0, label: "Providers" });
  //   navigate(`/view-all/false/${e.value}/${e.label}`);
  // };

  // const handleChangeProvider = (value) => {
  //   offset.current = 1;
  //   setSelectedProvider(value);
  //   localStorage.setItem("backGameValue", JSON.stringify(value));
  //   getGames({
  //     searchValue: searchInput,
  //     providerId: value?.value,
  //     popular: selectedSort,
  //     name,
  //   });
  // };
  useEffect(() => {
    if (params?.category) setSelectedCategory(Number(category));
    if (params?.provider !== "false" && params?.provider)
      setSelectedProvider({ value: Number(provider), label: name });
  }, [params]);

  // const handleChangeSort = (e) => {
  //   setSelectedSort(e?.target?.value);
  //   getGames({ searchValue: searchInput, popular: e?.target?.value });
  // };

  useEffect(() => {
    dispatch(getProvidersStart());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSubCategoryStart());
    let payload = {
      providerId: provider !== "false" ? provider : null,
      subCategoryId: category !== "false" ? category : null,
      limit: 21,
      pageNumber: 1,
      popular: selectedSort,
      name,
      coin: selectedWallet,
    };
    if (localStorage.getItem("backGame")) {
      if (games.length > 0) {
        const scrollPosition = localStorage.getItem("scrollPos");
        window.scrollTo(0, parseInt(scrollPosition) || 0);
        return;
      }
    } else {
      if (isAllgame) {
        payload.allGame = true;
      }
      dispatch(getGamesStart(payload));
    }
  }, [dispatch, provider, category, userId, selectedWallet]);

  const getGames = ({
    searchValue,
    popular,
    loadMoreGames,
    categoryId,
    providerId,
  }) => {
    let filters = {
      providerId: null,
      subCategoryId: null,
      limit: 21,
      pageNumber: 1,
      loadMoreGames,
      popular,
      name,
      coin: selectedWallet,
    };
    if (isAllgame) {
      filters.allGame = true;
    }
    if (searchValue !== null) {
      filters.search = searchValue || null;
      filters.providerId = selectedProvider?.value || null;
    }

    if (
      (provider !== "false" && provider) ||
      selectedProvider?.value ||
      selectedProvider?.value !== 0 ||
      providerId
    ) {
      if (!providerId) filters.providerId = selectedProvider?.value;
      else filters.providerId = providerId;
    }
    if (category !== "false" || selectedCategory || categoryId) {
      if (!categoryId)
        filters.subCategoryId = !providerId ? selectedCategory : null;
      else {
        filters.subCategoryId = !providerId ? categoryId : null;
      }
    }
    if (loadMoreGames) {
      filters.search = searchInput || null;
      filters.pageNumber = offset.current;
    }
    if (!loadMoreGames) {
      filters.search = searchValue || null;
    }
    dispatch(getGamesStart({ ...filters }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchGames = useCallback(
    debounce((searchValue) => {
      getGames({ searchValue, popular: selectedSort });
      offset.current = 1;
    }, 500),
    [selectedProvider, selectedSort, selectedCategory]
  );

  console.log(searchGames);

  const resetAll = () => {
    setSelectedProvider({ value: 0, label: "Providers" });
    setSearchInput("");
    setSelectedSort(0);
    let payload = {
      providerId: provider !== "false" ? provider : null,
      subCategoryId: category !== "false" ? category : null,
      limit: 21,
      pageNumber: 1,
      popular: selectedSort,
      name,
      coin: selectedWallet,
    };
    if (isAllgame) {
      payload.allGame = true;
    }
    dispatch(getGamesStart(payload));
  };
  console.log(resetAll);
  const handleMoreGames = () => {
    const scrollY = window.scrollY;
    localStorage.setItem("scrollPos", scrollY);
    offset.current += 1;
    getGames({ loadMoreGames: true });
  };
  //Uncomment for the new game header
  // const LeftButton = () => {
  //   const { scrollPrev } = React.useContext(VisibilityContext);
  //   return (
  //     <Button className={classes.leftArrow} onClick={() => scrollPrev()}>
  //       <KeyboardArrowLeftTwoToneIcon />
  //     </Button>
  //   );
  // };

  // const RightButton = () => {
  //   const { scrollNext } = React.useContext(VisibilityContext);
  //   return (
  //     <Button className={classes.rightArrow} onClick={() => scrollNext()}>
  //       <NavigateNextTwoToneIcon />
  //     </Button>
  //   );
  // };

  const subCategoryOptions = subCategoryFiltered.map((item) => ({
    value:
      item.masterGameSubCategoryId !== undefined
        ? item.masterGameSubCategoryId
        : 14,
    label: formatCategoryName(item.name),
  }));

  console.log(subCategoryOptions);

  const options = [{ value: 0, label: "All" }];

  console.log(options);

  return (
    <div>
      <Grid className={classes.contentWrap}>
        <NewGameHeader
          subCategoryFiltered={subCategoryFiltered}
          handleChangeCategory={handleChangeCategory}
          resetAll={resetAll}
          searchInput={searchInput}
          handleChangeSearch={handleChangeSearch}
        />

        {/* <Grid className={classes.innerHeading}>
          <Grid className={classes.innerHeadingLeft}>
            <Grid className={classes.filterSection}>
              <ReactSelect
                className="basic-single"
                classNamePrefix="select"
                value={selectedSort}
                onChange={handleChangeSort}
                closeMenuOnSelect={true}
                isSearchable={false}
                placeholder="All"
                name="color"
                options={options}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "100% ", // Set width to 100%
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: "8px",
                    marginTop: "-1px",
                    width: "100%",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    borderRadius: "8px", // Set the radius for the items
                    width: "100%",
                    background: state.isSelected ? "#24242b" : "transparent",
                    color: state.isSelected ? "#fff" : "#fff",
                    "&:hover": {
                      backgroundColor: "#24242b",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: darkMode ? "#fff" : "#24242b",
                    borderRadius: "8px",
                  }),
                  menuList: (s) => ({
                    ...s,
                    background: "#24242b",
                    borderRadius: "12px",
                    fontSize: "14px",
                    border: "1.4px solid rgba(255, 255, 255, 0.04)",
                    boxShadow:
                      "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "8px",
                  colors: {
                    ...theme.colors,
                    neutral0: darkMode ? "#2B2B38" : "#f7df94",
                    primary25: "rgba(123, 98, 218, 0.16)",
                    neutral80: "#fff",
                  },
                })}
              />
              <Box className={`${classes.filterText} ${classes.sortText}`}>
                <img src="/images/sort.svg" alt="sort" />
                <span>Sort by</span>
              </Box>
            </Grid>

            <Grid className={`${classes.filterSection} filterSectionSort`}>
              <ReactSelect
                className="basic-single"
                classNamePrefix="select"
                defaultValue={
                  localStorage.getItem("backGameValue") &&
                  JSON.parse(localStorage.getItem("backGameValue"))
                }
                value={
                  (localStorage.getItem("backGameValue") &&
                    JSON.parse(localStorage.getItem("backGameValue"))) ||
                  selectedProvider
                }
                onChange={(e) => {
                  handleChangeProvider(e);
                }}
                closeMenuOnSelect={true}
                isSearchable={false}
                name="color"
                placeholder="Providers"
                options={providerList}
                maxMenuHeight={150}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: "8px",
                    marginTop: "-1px",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    borderRadius: "8px", // Set the radius for the items
                    background: state.isSelected ? "#24242b" : "transparent",
                    color: state.isSelected ? "#fff" : "#fff",
                    "&:hover": {
                      backgroundColor: "#24242b",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: darkMode ? "#fff" : "#24242b",
                    borderRadius: "8px",
                  }),
                  menuList: (s) => ({
                    ...s,
                    background: "#24242b",
                    borderRadius: "12px",
                    fontSize: "14px",
                    border: "1.4px solid rgba(255, 255, 255, 0.04)",
                    boxShadow:
                      "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "8px",
                  colors: {
                    ...theme.colors,
                    neutral0: darkMode ? "#2B2B38" : "#f7df94",
                    primary25: "rgba(123, 98, 218, 0.16)",
                    neutral80: "#fff",
                  },
                })}
              />

              <Box className={classes.filterText}>
                <img src="/images/filter.svg" alt="filter" />
                <span>Filter by</span>
              </Box>
            </Grid>
          </Grid>

          <Grid className={classes.resetSearch}>
            <Grid className={classes.homeFilter}>
              <TextField
                autoComplete="off"
                autoCorrect="off"
                id="search"
                className={classes.searchInput}
                type="text"
                variant="outlined"
                value={searchInput}
                onChange={handleChangeSearch}
                placeholder="Search For Game"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src="/images/search.svg" alt="search" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Button className={classes.resetBtn} onClick={resetAll}>
              Reset
            </Button>
          </Grid>
        </Grid> */}

        <Grid
          container
          className={`${classes.gamesWrapper} ${classes.ViewAllGame}`}
        >
          {loading ? (
            <Typography className={classes.circulerLoader}>
              <div className={"load"} />
            </Typography>
          ) : games?.length > 0 ? (
            games.map((game, index) => (
              <Game
                key={`${game.masterCasinoGameId}${index}`}
                thumbnailUrl={game.thumbnailUrl}
                name={game.name}
                gameId={game.masterCasinoGameId}
                categoryName={game.MasterGameSubCategory?.name}
                providerName={game?.name}
                isFavorite={!!game.isFavorite}
                providerId={game?.masterCasinoProviderId}
              />
            ))
          ) : (
            <h1 className={classes.noGamesMsg}>No Games Found</h1>
          )}
        </Grid>

        <Grid container textAlign="center" justifyContent="center">
          {!moreGamesLoading && !loading && gamesCount > games?.length && (
            <Grid className={classes.loadMoreBar}>
              <Grid className={classes.ShowMoreBar}>
                <LinearProgress
                  thickness={3}
                  value={gameListProgress}
                  variant="determinate"
                  className={classes.linearBar}
                />
                <p>
                  Displaying {games?.length} of {gamesCount}
                </p>
              </Grid>

              <Button
                onClick={() => handleMoreGames()}
                className={`${classes.btnPrimary} ${classes.loadMoreButton}`}
              >
                Show More
              </Button>
            </Grid>
          )}
          {moreGamesLoading && (
            <Typography>
              <div className={"load"} />
            </Typography>
          )}
        </Grid>

        <Grid>
          <Provider offset={offset} />
        </Grid>
        {/* 
        <Grid>
          <LiveTable />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default ViewAll;
