import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  footerWrapper: {
    background: theme.palette?.background?.footer,
    padding: "30px",
    display: "inline-block",
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      padding: "30px",
      // marginBottom: "30px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
      // marginBottom: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
      marginTop: "12px",
    },
  },
  footerWrap: {
    padding: "0 50px 0 50px",
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },

  footerLogo: {
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    justifyContent: "center",
    color: theme?.palette?.text?.default,
    marginBottom: "0",
    [theme.breakpoints.down("sm")]: {
      marginRight: "5px",
    },
    "& img": {
      width: "100px",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "100px",
        width: "100%",
      },
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
    },
    [theme.breakpoints.down("767")]: {
      maxWidth: "80px !important",
    },
  },

  logo: {
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  mobLogo: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },

  logoName: {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "35px",
    paddingLeft: "12px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },

  footrAboutWrap: {
    "& p": {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "150%",
      color: "#757786",
      "& a": {
        color: theme?.palette?.text?.default,
        textDecoration: "none",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        padding: "0 25px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
  },

  footerLink: {
    [theme.breakpoints.down(1536)]: {
      maxWidth: "50% !important",
      flexBasis: "50% !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0!important",
      maxWidth: "auto !important",
      flexBasis: "auto!important",
    },
    "& h6": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "23px",
      display: "flex",
      alignItems: "flex-end",
      color: theme?.palette?.text?.default,
      marginBottom: "20px",
      marginTop: "0",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        marginBottom: "9px",
        lineHeight: "17px",
        justifyContent: "center",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    "& ul": {
      padding: "0",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        gap: "9px",
      },
      "& li": {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "23px",
        display: "flex",
        alignItems: "flex-end",
        color: "#757786",
        marginBottom: "10px",
        padding: "0",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
          marginBottom: "0px",
          lineHeight: "14px",
          padding: "0",
          justifyContent: "center",
        },
        [theme.breakpoints.down("sm")]: {
          justifyContent: "flex-start",
          fontSize: "12px",
        },
      },
    },
  },

  footerPaymentFollowWrap: {
    maxWidth: "274px",
    width: "100%",
    paddingTop: "20px !important",
    [theme.breakpoints.down(1450)]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("991")]: {
      display: "flex",
      flexDirection: "row",
      padding: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    "& h6": {
      marginTop: "0",
      marginBottom: "13px",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "23px",
      display: "flex",
      textTransform: "capitalize",
      color: theme?.palette?.text?.default,
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        marginBottom: "9px",
        lineHeight: "17px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "12px",
      },
    },
    "& ul": {
      padding: "0",
      margin: "0 0 20px 0",
      display: "flex",
      gap: "19px",
      flexWrap: "wrap",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        gap: "9px",
        margin: "0",
      },
      "& li": {
        padding: "0",
        margin: "0",
        display: "contents",
        width: "30px",
        "& img": {
          [theme.breakpoints.down("md")]: {
            height: "14px",
          },
        },
      },
    },
  },

  footerCryptoLogo: {
    lineHeight: "68px",
    borderTop: "1px solid rgba(63, 70, 85, 0.3)",
    borderBottom: "1px solid rgba(63, 70, 85, 0.3)",
    margin: "25px 0 30px",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      margin: "25px 0px 20px",
      padding: "20px 0px",
    },
    [theme.breakpoints.down("md")]: {
      lineHeight: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    "& ul": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "nowrap",
      gap: "24px 20px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        paddingBottom: "0px",
        paddingTop: "0px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        gap: "24px 0px",
        flexWrap: "nowrap",
        overflowY: "hidden",
        overflowX: "auto",
        justifyContent: "flex-start",
      },
      "& li": {
        textAlign: "center",
        width: "130px",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
          padding: "0",
          // width: "93px",
        },
        [theme.breakpoints.down("sm")]: {
          padding: "0",
          // width: "calc(25% - 15px)",
          display: "flex",
        },
        "& img": {
          [theme.breakpoints.down("md")]: {
            width: "75px",
          },
        },
        "&:last-child": {
          "& img": {
            [theme.breakpoints.down("md")]: {
              width: "15px",
            },
          },
        },
      },
    },
  },

  copyRight: {
    display: "inline-block",
    width: "100%",
    "& p": {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#666773",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      "& span": {
        color: theme?.palette?.text?.default,
      },
    },
  },
  currencyWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    gap: "15px",
    [theme.breakpoints.down(1450)]: {
      flexDirection: "row",
      gap: "10px",
    },

    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      gap: "0",
    },
    [theme.breakpoints.down(991)]: {
      flexDirection: "row",
      gap: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "10px",
      flexDirection: "row",
      flexWrap: "wrap",
      marginBottom: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px",
    },
  },

  currencyDetail: {
    [theme.breakpoints.down(1450)]: {
      justifyContent: "center",
      flexWrap: "wrap",
    },
    [theme.breakpoints.down(1200)]: {
      borderRadius: "8px",
      marginBottom: "10px",
      width: "100%",
    },
    [theme.breakpoints.down(991)]: {
      borderRadius: "8px",
      marginBottom: "0",
      width: "48%",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "8px",
      marginBottom: "0",
      width: "calc(25% - 10px)",
      gap: "2px",
    },
    display: "flex",
    background: theme.palette?.background?.footerCurrencyChip,
    boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "24px",
    padding: "7px",
    marginBottom: "30px",
    "& p": {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "23px",
      display: "flex",
      alignItems: "center",
      color: "#757786",
      margin: "0",
      gap: "5px",
      "& span": {
        "& small": {
          fontSize: "16px",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        lineHeight: "13px",
        justifyContent: "center",
      },
      "&:nth-child(2)": {
        paddingLeft: "5px",
        color: theme?.palette?.text?.default,
      },
      "& img": {
        [theme.breakpoints.down("md")]: {
          width: "14px",
        },
      },
    },
  },
  mobiFooter: {
    display: "none",
    backgroundImage: "url('/images/footer-bg.svg')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",

    height: "80px",
    width: "calc(100% + 0px)",
    padding: "10px 20px",
    background: "transparent",
    margin: "0px auto",
    [theme.breakpoints.down("md")]: {
      display: "block",
      position: "fixed",
      bottom: "-1px",
      left: "0",
      zIndex: "999",
    },
    [theme.breakpoints.down("sm")]: {
      zIndex: "99",
    },
    "& ul": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "82px",
      "& li": {
        minWidth: "35px",
        "& a": {
          textDecoration: "none",
          position: "relative",
        },
        "& p": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          gap: "5px",
          position: "relative",

          "& ijmg": {
            width: "12px",
          },
          "& svg": {
            width: "19px",
            height: "19px",
          },
          "& span": {
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "11px",
            lineHeight: "14px",
            textAlign: "center",
            color: "#757786",
          },
        },
      },
    },
  },

  chatCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "radial-gradient(113.34% 127.34% at 50.43% -17.05%, #FFD233 0%, #FF8A00 100%)",
    boxShadow: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.25))",
    borderRadius: "24px",
    height: "15px",
    minWidth: "15px",
    width: "15px",

    position: "absolute",
    top: "-5px",
    left: "18px",
    "& span": {
      color: "#fff !important",
      fontSize: "9px !important",
    },
  },

  active: {
    height: "60px",
    width: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    top: "-1px",
    "&:before": {
      content: "''",
      height: "135px",
      width: "135px",
      background: "url('/images/menu-blue.png')",
      position: "absolute",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      display: "block",
      marginTop: "-4px",
    },

    "& img": {
      width: "24px",
    },
    "& a": {
      marginTop: "-16px",
      marginLeft: "2px",
    },
  },

  actives: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& svg path": {
      fill: "#F5F5F6",
    },
    "& svg line": {
      stroke: "#F5F5F6",
    },
    "& span": {
      color: "#fff !important",
    },
    "&:before": {
      background: "#4f53ae",
      bottom: "-25px",
      content: "''",
      display: "block",
      filter: "blur(9.5px)",
      height: "36px",
      left: "50%",
      opacity: ".6",
      position: "absolute",
      transform: "translate(-50%) matrix(0,-1,-1,0,0,0)",
      width: "10px",
    },
    "&:after": {
      content: "''",
      background:
        "linear-gradient(101deg,rgba(237,29,73,0),#5d4fa6 48.44%,rgba(237,29,73,0))",
      borderRadius: "50px 50px 0 0",
      bottom: "-9px",
      height: "3px",
      left: "50%",
      position: "absolute",
      transform: "translate(-50%)",
      width: "36px",
    },
  },

  footerflex: {
    gap: "35px",
    marginLeft: "0",
    [theme.breakpoints.down(1919)]: {
      gap: "0",
      margin: "0",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },

    "& .MuiGrid-item": {
      padding: "0!important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  footerLinWrap: {
    padding: "20px 0px 32px",
    [theme.breakpoints.down("sm")]: {
      gap: "16px",
      flexWrap: "nowrap",
    },
  },
  footerBottomFollowWrap: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footerBottomMethods: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));
