/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";

import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import useViewStyles from "../ViewAll/ViewAll.styles";
import useStyles from "./Lobby.styles";
import lobbyService from "../../../services/casino/lobby";
import Game from "../Game";

export default function SearchGamesListing({
  search,
  category,
  page,
  setPage,
  isMobile,
}) {
  const {
    authentication: { userId },
  } = useSelector((state) => state);
  const classes = useStyles();
  const [gameListProgress, setGameListProgress] = useState(0);
  const viewStyles = useViewStyles({ gameProgressWidth: gameListProgress });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showLess, setShowLess] = useState(false); // New state for "Show Less" button

  const gameListRef = useRef(null);

  useEffect(() => {
    let ac = new AbortController();
    const s = ac.signal;
  
    if (search || category) {
      setLoading(true);
      if (!data || page === 1) {
        setData({});
      }
      lobbyService
        .getAllSearchedGames(
          {
            loadMoreSeachedGames: null,
            isMobile: false,
            page,
            limit: isMobile ? 9 : 21,
            search,
            subCategoryId:
              category?.value !== "lobby"
                ? category?.value
                  ? category?.value
                  : category
                : null,
            userId,
          },
          s
        )
        .then((res) => {
          setData((prev) => {
            if (page === 1 || !prev?.games) {
              return res.data;
            } else {
              const games = [
                ...(prev?.games || []),
                ...(res?.data?.games || []),
              ];
              return {
                ...prev,
                ...res.data,
                games,
              };
            }
          });
          
          setShowLess(page >= res.data?.numberOfPages);
  
          if (page === 1 && gameListRef.current) {
            gameListRef.current.scrollIntoView({ behavior: "smooth" });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  
    return () => {
      ac.abort();
    };
  }, [search, page, category]);
  

  useEffect(() => {
    if (data?.games?.length !== 0 && data?.queriedCount) {
      const progress = (data?.games?.length / Number(data?.queriedCount)) * 100;
      setGameListProgress(progress);
    }
  }, [data]);

  const handleShowLess = () => {
  // Scroll to the top of the game list
  if (gameListRef.current) {
    const offsetTop =
      gameListRef.current.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  }
  // Reset to the first page
  setPage(1);
  setShowLess(false); // Hide the "Show Less" button
};

  return (
    <>
      <Grid
        container
        className={`${viewStyles.gamesWrapper} ${viewStyles.ViewAllGame}`}
        ref={gameListRef} // Reference to the container
      >
        {data?.games?.length === 0 ? (
          <h1 className={classes.noGamesMsg}>No Games Found</h1>
        ) : (
          data?.games?.map((game, index) => (
            <Game
              key={`${game.masterCasinoGameId}${index}`}
              thumbnailUrl={game.thumbnailUrl}
              name={game.name}
              gameId={game.masterCasinoGameId}
              categoryName={game.MasterGameSubCategory?.name}
              providerName={game?.name}
              isFavorite={!!game.isFavorite}
              providerId={game?.masterCasinoProviderId}
            />
          ))
        )}
      </Grid>
      <Grid>
        <div className="gamelistingLoaderMainWrap">
          {loading ? (
            <div className="searchGamePageWrap">
              <Typography
                className={`searchLoaderWrap ${classes.circulerLoader}`}
              >
                <div className={"load loaderCenter"} />
              </Typography>
            </div>
          ) : null}
          {page < data?.numberOfPages && (
            <Grid
              container
              textAlign="center"
              justifyContent="center"
              style={{ marginBottom: "20px", display: "block" }}
              className={viewStyles.loadMoreBar}
            >
              <Grid className={viewStyles.ShowMoreBar}>
                <LinearProgress
                  thickness={3}
                  value={gameListProgress}
                  variant="determinate"
                  className={` mobileProgresBar ${viewStyles.linearBar}`}
                />
                <p>
                  Displaying {data?.games?.length} of {data?.queriedCount}
                </p>
              </Grid>
              <Button
                className={`${viewStyles.btnPrimary} ${viewStyles.loadMoreButton}`}
                onClick={() => setPage((p) => p + 1)}
              >
                Show More
              </Button>
            </Grid>
          )}
          {showLess && data?.games?.length && data?.numberOfPages !== 1 ? (
            <Grid
              container
              textAlign="center"
              justifyContent="center"
              style={{ marginBottom: "20px", display: "block" }}
              className={viewStyles.loadMoreBar}
            >
              <Grid className={viewStyles.ShowMoreBar}>
                <LinearProgress
                  thickness={3}
                  value={gameListProgress}
                  variant="determinate"
                  className={`mobileProgresBar ${viewStyles.linearBar}`}
                />
                <p>
                  Displaying {data?.games?.length} of {data?.queriedCount}
                </p>
              </Grid>
              <Button
                className={`${viewStyles.btnPrimary} ${viewStyles.loadMoreButton}`}
                onClick={handleShowLess}
              >
                Show Less
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </div>
      </Grid>
    </>
  );
}
