import React from "react";

import { Button } from "@mui/material";
//import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import { ErrorMessage /* LOGIN_SIGNUP_STATES */ } from "./constants";
//import authenticationService from "../../../services/authentication";
import { signUpStart } from "../../../redux-saga/redux/auth/authentication";

import "./login.css";

function Register({ register, formState: { errors }, handleSubmit, setState }) {
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    // const response = await authenticationService.emailExists({
    //   email: data.email,
    // });
    // if (response.status === 403) {
    //   toast.error(response.message, { id: "email-exists-error" });
    //   return;
    // }
    dispatch(
      signUpStart({
        email: data.email,
        password: window.btoa(data.password),
        confirmPassword: window.btoa(data.confirmPassword),
        referralCode: "",
      })
    );
    // if (data) {
    //   setState(LOGIN_SIGNUP_STATES.RegisterConfirmation);
    // }
  };
  return (
    <div className="loginFormMainWrap">
      <div className="inputWrap">
        <div className="input-login-form-wrap">
          <div className="input-container-login">
            <label htmlFor="email" className="input-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="custom-input"
              placeholder="user@gmail.com"
              {...register("email")}
            />
            <ErrorMessage error={errors?.email} />
          </div>
          <div className="input-container-login position-relative">
            <label htmlFor="password" className="input-label">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="custom-input"
              placeholder="********"
              {...register("password")}
            />
            <ErrorMessage error={errors?.password} />
          </div>
          <div className="input-container-login position-relative">
            <label htmlFor="password" className="input-label">
              Confirm password
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="custom-input"
              placeholder="********"
              {...register("confirmPassword")}
            />
            <ErrorMessage error={errors?.confirmPassword} />
          </div>
        </div>
        <div className="logInBtnWrap">
          <Button type="button" onClick={handleSubmit(onSubmit)}>
            Register{/* Next step */}
          </Button>
        </div>

        <div className="divider-container register-divider">
          <span className="divider-text">Or register with social media</span>
        </div>
      </div>
      <div className="loginSocialBtnWrap">
        <Button>
          <img src="/images/loginMeta.png" alt="" />
          Meta
        </Button>
        <Button>
          <img src="/images/loginGoogle.png" alt="" />
          Google
        </Button>
      </div>
    </div>
  );
}

export default Register;
