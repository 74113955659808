import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Grid, TextField } from "@mui/material";

import useStyles from "./SearchGames.styles";

export default function NewSearchGames({ search, setSearch, setPage }) {
  const classes = useStyles();

  const handleSearch = (event) => {
    const value = event.target.value;
    if (value.length > 20) return;
    setPage(1);
    setSearch(value);
  };

  const reset = () => {
    setSearch("");
  };

  return (
    <Grid className={` landscapeSearch ${classes.customSearchbar}`}>
      <TextField
        autoComplete="off"
        autoCorrect="off"
        id="outlined-basic"
        variant="outlined"
        placeholder={"Search for Game"}
        value={search}
        onChange={handleSearch}
      />
      {search ? (
        <CloseIcon
          onClick={reset}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "17px",
            top: "7px",
          }}
        />
      ) : (
        <img
          src="/images/search.svg"
          alt="search"
          style={{ filter: "invert(1)", opacity: "0.3" }}
        />
      )}
    </Grid>
  );
}
