/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  //useCallback,
  useContext,
  useState,
  useTransition,
} from "react";

import {
  Grid,
  Typography,
  // CircularProgress,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
//import { useNavigate } from "react-router-dom";

import useStyles from "./Lobby.styles";
import { carouselGameLimit } from "../../../utils/constants";
// import  getLandingGames from "../../../redux-saga/redux/casino/lobby";
//updateLandingGames,
import { formatCategoryName } from "../../../utils/helper";
import Game from "../Game";
import Provider from "../Provider";
// import LiveTable from "../../../components/LiveTable";
import config from "../../../config";
import lobbyService from "../../../services/casino/lobby";
import { getLandingGamesSuccess } from "../../../redux-saga/redux/casino/lobby";
//import { updateLandingGamesInFav } from "../../../redux-saga/redux/casino/lobby/games";

const Games = ({ setCategory, setPage }) => {
  console.log("hello call 1");
  const classes = useStyles();
  const dispatch = useDispatch();
  //const navigate = useNavigate();
  //const { scrollNext, scrollPrev } = useContext(VisibilityContext)
  const {
    authentication: { userId, selectedWallet },
    themeSettings: { darkMode },
  } = useSelector((state) => state);
  const [gamesList, setGamesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    setLoading(true);
    lobbyService
      .getLandingGames({
        limit: 20,
        isMobile: isMobile,
        isFeatured: true,
        coin: selectedWallet || "coin",
        loadMoreGames: null,
        userId: userId || null,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getLandingGamesSuccess({ data: res.data }));
          startTransition(() => {
            setGamesList(res.data);
          });
        }
      })
      .catch((err) => {
        console.error("[Error]", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);

  // const { recentsGames } = useSelector((state) => state.favorites);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const LeftButton = () => {
    const { scrollPrev } = useContext(VisibilityContext);
    return (
      <Button
        className={`${classes.leftArrow} leftArrowIcon`}
        onClick={() => scrollPrev()}
      >
        <KeyboardArrowLeftTwoToneIcon />
      </Button>
    );
  };

  const RightButton = () => {
    const { scrollNext } = useContext(VisibilityContext);
    return (
      <Button
        className={`${classes.rightArrow} rightArrowIcon`}
        onClick={() => scrollNext()}
      >
        <NavigateNextTwoToneIcon />
      </Button>
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     getLandingGames({
  //       limit: 20,
  //       isMobile: isMobile,
  //       isFeatured: true,
  //       coin: selectedWallet || "coin",
  //     })
  //   );
  // }, [dispatch, userId, selectedWallet]);

  const getCatData = (gameData) => {
    const obj = {
      iconName: gameData?.["MasterGameSubCategory.icon_name"],
      masterGameSubCategoryId:
        gameData?.["MasterGameSubCategory.master_game_sub_category_id"],
    };
    return obj;
  };
  const handleOpenGame = (category, categoryData) => {
    setPage(1);
    if (category === "Hot Games") {
      setCategory({
        value: 20,
        label: "Hot Games",
      }); /* `/view-all/false/20/${category}` */
    } else if (category === "New Games") {
      setCategory({
        value: "all-games",
        label: "New Games",
      });
    } else if (category === "Recent Played") {
      setCategory({
        value: "Recent Played",
        label: "Recent Played",
      });
    } else {
      setCategory({
        value: categoryData.masterGameSubCategoryId,
        label: category,
      }); /* `/view-all/false/${categoryData.masterGameSubCategoryId}/${category}` */
    }
  };
  // const handleClick = (category, categoryData) => {
  //   if (category === "Recent Played") {
  //     navigate("/recents");
  //     return;
  //   } else if (category === "New Games") {
  //     navigate("/all-games");
  //   } else {
  //     navigate(
  //       `/view-all/false/${
  //         categoryData?.masterGameSubCategoryId || false
  //       }/${category}`
  //     );
  //   }
  // };
  return (
    <>
      <Grid className={`  ${classes.wrap}`}>
        {loading || isPending ? (
          <div className="homePageLoaderContent">
            <Grid textAlign="center" className={classes.circulerLoader}>
              <div className={"load"} />
            </Grid>
          </div>
        ) : (
          <>
            {gamesList && gamesList?.length > 0 ? (
              <>
                {Object.keys(gamesList[0]).map((category) => {
                  const categoryData =
                    gamesList[0][category].length > 0
                      ? getCatData(gamesList[0][category][0])
                      : null;
                  const games = gamesList[0][category];
                  return categoryData ? (
                    <Grid key={category} className={classes.contentWrap}>
                      <Grid className={classes.innerHeading}>
                        <Grid className={classes.innerHeadingLeft}>
                          {darkMode ? (
                            <img
                              src={
                                category === "Hot Games"
                                  ? "https://api.cryptorolls.com/hotgame-star-icon.svg"
                                  : category === "New Games"
                                  ? `${config?.baseUrl}/new.svg`
                                  : categoryData?.iconName
                                  ? categoryData.iconName
                                  : "/images/star-icon.svg"
                              }
                              alt="Star Icon"
                            />
                          ) : (
                            <img
                              src={
                                category === "Hot Games"
                                  ? "https://api.cryptorolls.com/hotgame-star-icon.svg"
                                  : category === "New Games"
                                  ? `${config?.baseUrl}/new.svg`
                                  : categoryData?.iconName
                                  ? categoryData.iconName
                                  : "/images/light-star-icon.svg"
                              }
                              alt="Star Icon"
                            />
                          )}
                          <Typography variant="h6" gutterBottom>
                            {formatCategoryName(category) || ""}
                          </Typography>
                        </Grid>
                        {games?.length > 6 && (
                          <button
                            onClick={() =>
                              handleOpenGame(category, categoryData)
                            }
                            className={` seeAllBtnLandscape ${classes.btnSecondary}`}
                          >
                            See all
                          </button>
                        )}
                      </Grid>
                      <Grid
                        className={`${classes.filterWrap} ${classes.filterWrapSlider}`}
                      >
                        <ScrollMenu
                          LeftArrow={
                            games?.length > carouselGameLimit ? (
                              <LeftButton />
                            ) : null
                          }
                          RightArrow={
                            games?.length > carouselGameLimit ? (
                              <RightButton />
                            ) : null
                          }
                          wrapperClassName="horizontalScrollWrapper"
                        >
                          {console.log(games.length)}
                          {games &&
                            games?.length > 0 &&
                            games?.map((game, index) => (
                              <Game
                                providerName={
                                  game?.MasterCasinoProvider?.name || null
                                }
                                key={`${game?.masterCasinoGameId}${index}`}
                                thumbnailUrl={game?.thumbnailUrl}
                                name={game?.name}
                                gameId={game?.masterCasinoGameId}
                                filterBy={"Category"}
                                filterName={formatCategoryName(category)}
                                searchGame={false}
                                isFavorite={!!game?.isFavorite}
                                providerId={game?.masterCasinoProviderId}
                              />
                            ))}
                        </ScrollMenu>
                      </Grid>
                    </Grid>
                  ) : null;
                })}
              </>
            ) : (
              <h1 className={classes.noGamesMsg}>No Games Found</h1>
            )}
          </>
        )}
      </Grid>
      <Grid>
        <Provider />
      </Grid>
    </>
  );
};

export default Games;
