import React, { useEffect, useRef, useState } from "react";

import { Button } from "@mui/material";
import toast from "react-hot-toast";

import authenticationService from "../../../../services/authentication";
import { validateEmail } from "../../../../utils/helper";
import { ErrorMessage } from "../constants";

function EnterVerificationOtp({ mail, setForgotViews, handleBackToLogin }) {
  const [values, setValues] = useState(Array(6).fill(""));
  const inputsRef = useRef([]);
  const [timeout, setTimeout] = useState(60);
  const [error, setError] = useState("");

  useEffect(() => {
    if (timeout === 0) return;
    const id = setInterval(() => {
      setTimeout((prev) => prev - 1);
    }, 1000);
    return () => {
      clearTimeout(id);
    };
  }, [timeout]);

  useEffect(() => {
    if (inputsRef.current && inputsRef.current.length > 0) {
      inputsRef?.current?.[0]?.focus?.();
    }
  }, [inputsRef]);

  const handleChange = (index, event) => {
    const { value } = event.target;
    const newValues = [...values];

    if (/^\d{6}$/.test(value)) {
      const digits = value.split("");
      for (let i = 0; i < digits.length; i++) {
        newValues[i] = digits[i];
      }
      setValues(newValues);
      inputsRef.current[5].focus();
      return;
    }

    if (/^\d?$/.test(value)) {
      newValues[index] = value;
      setValues(newValues);

      if (value && index < values.length - 1) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && values[index] === "" && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleVerifyOtp = async () => {
    setError("");
    const response = await authenticationService.verifyForgotPasswordOtp({
      otp: values.join(""),
      email: mail,
    });
    if (response.status === 200) {
      setForgotViews("enter_password");
      return;
    }
    if (response.message === "OTP not verified") {
      setError("Incorrect Code");
      return;
    }
  };

  const handleResendOtp = async () => {
    setError("");
    if (validateEmail(mail)) {
      const response = await authenticationService.sendOtp({
        email: mail.toLowerCase(),
      });
      if (response.status === 200) {
        setTimeout(60);
        return;
      }
      toast.error(
        response.status === 404 ? response.message : "Internal Server Error",
        {
          id: "otp-sent-failure-issue",
        }
      );
      return;
    }
  };

  useEffect(
    () => () => {
      setError("");
    },
    []
  );

  return (
    <div className="enterMailMainWrap loginFormMainWrap masterMainWrap">
      <p>
        We have sent a confirmation code to {mail}, please enter the
        confirmation code below to reset your password
      </p>
      <div className="otpMainWrap">
        <div className="input-fields-container">
          {values.map((value, index) => (
            <input
              key={`input-${index}`}
              type="number"
              maxLength="1"
              value={value}
              onChange={(event) => handleChange(index, event)}
              onKeyDown={(event) => handleKeyDown(index, event)}
              ref={(el) => (inputsRef.current[index] = el)}
              className="input-box"
              placeholder="-"
            />
          ))}
        </div>
      </div>{" "}
      <ErrorMessage error={{ message: error }} />
      <div>
        {timeout === 0 ? (
          <p className="resendBottomTxt">
            <span
              className="blueColor"
              onClick={handleResendOtp}
              tabIndex="0"
              role="button"
              onKeyDown={() => {}}
            >
              Resend
            </span>{" "}
            Confirmation OTP
          </p>
        ) : (
          <p className="resendBottomTxt">
            Resend confirmation code in{" "}
            <span className="blueColor">{timeout} seconds</span>
          </p>
        )}
      </div>
      <div className="bottomBtnFixedWrap">
        <div className="logInBtnWrap">
          <Button type="button" key="enter_mail" onClick={handleVerifyOtp}>
            Verify OTP
          </Button>
        </div>
        <div className="logInBtnWrap backBtnFull">
          <Button onClick={handleBackToLogin}>Back to login</Button>
        </div>
      </div>
    </div>
  );
}

export default EnterVerificationOtp;
