import React, { useState } from "react";

import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import LoginForm from "./loginForm";
import Register from "./register";
//import AgreeData from "./agree";
import ForgotPassword from "./forgotPassword";
import { LOGIN_SIGNUP_ENUMS, registerValidationSchema } from "./constants";

import "./login.css";

const formData = {
  email: "",
  password: "",
  confirmPassword: "",
};

function LoginNew({ view, setOpen, setView }) {
  // states
  const [state, setState] = useState("");

  const registerHooks = useForm({
    resolver: yupResolver(registerValidationSchema),
    defaultValues: formData,
  });

  // rendering states
  // if (state.startsWith("register_")) {
  //   return (
  //     <AgreeData
  //       state={state}
  //       setState={setState}
  //       setOpen={setOpen}
  //       data={registerHooks.getValues()}
  //     />
  //   );
  // }

  if (state === "forgot_password") {
    return <ForgotPassword setOpen={setOpen} setState={setState} />;
  }
  return (
    <div className="loginMainWrap">
      <div className="sticyDiv">
        <div className="loginHeadeWrap">
          <Box onClick={() => setOpen((prev) => !prev)} className="closeIcon">
            <img src="/images/loginCross.png" alt="" />
          </Box>
        </div>
        <div className="loginLogoWrap">
          <img src="/images/logo-svg.svg" alt="" />
          <h1>Welcome to Fortunarush</h1>
        </div>
      </div>
      <div className="tabs-wrapperLogin">
        <div className="tabs-container">
          <button
            className={`tab-button ${
              view === LOGIN_SIGNUP_ENUMS.signup ? "active" : ""
            }`}
            onClick={() => setView(LOGIN_SIGNUP_ENUMS.signup)}
          >
            Register
          </button>
          <button
            className={`tab-button ${
              view === LOGIN_SIGNUP_ENUMS.login ? "active" : ""
            }`}
            onClick={() => setView(LOGIN_SIGNUP_ENUMS.login)}
          >
            Log in
          </button>
        </div>

        <div className="tab-content">
          {view === "login" ? (
            <LoginForm setState={setState} />
          ) : (
            <Register setState={setState} {...registerHooks} />
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginNew;
