import React, { useState } from "react";

import { Box } from "@mui/material";
import { isIOS, isAndroid } from "react-device-detect";

import EnterMail from "./enterMail";
import EnterNewPassword from "./enterNewPassword";
import EnterVerificationOtp from "./enterVerificationOtp";

import "./forgotPassword.css";

const TempComponent = ({
  forgotViews,
  setForgotViews,
  mail,
  setMail,
  setOpen,
  handleBackToLogin,
}) => {
  switch (forgotViews) {
    case "enter_mail":
      return (
        <EnterMail
          setForgotViews={setForgotViews}
          mail={mail}
          setMail={setMail}
          handleBackToLogin={handleBackToLogin}
        />
      );
    case "enter_password":
      return (
        <EnterNewPassword
          setForgotViews={setForgotViews}
          mail={mail}
          setOpen={setOpen}
        />
      );
    case "verify_otp":
      return (
        <EnterVerificationOtp
          mail={mail}
          setForgotViews={setForgotViews}
          handleBackToLogin={handleBackToLogin}
        />
      );
    default:
      return <EnterMail setForgotViews={setForgotViews} />;
  }
};

export default function ForgotPassword({ setOpen, setState }) {
  const [forgotViews, setForgotViews] = useState("enter_mail");
  const [mail, setMail] = useState("");

  const handleBackToLogin = () => setState("");
  return (
    <div className="masterMainWrap">
      <div
        className="forgotPasswordMainWrap"
        style={{
          height: isIOS || isAndroid ? window.innerHeight : "100vh",
        }}
      >
        <div className="">
          <div className="loginHeadeWrap">
            <Box className="closeIcon" onClick={() => setOpen((prev) => !prev)}>
              <img src="/images/loginCross.png" alt="" />
            </Box>
          </div>
          <div className="loginLogoWrap">
            <img
              src="/images/logo-svg.svg"
              alt=""
              className="resetPasswordBannerImg"
            />
            <h1>Reset password</h1>
          </div>
        </div>
        <TempComponent
          mail={mail}
          setMail={setMail}
          forgotViews={forgotViews}
          setForgotViews={setForgotViews}
          setOpen={setOpen}
          handleBackToLogin={handleBackToLogin}
        />
      </div>
    </div>
  );
}
