import React from "react";

import {
  /*  Button,  Box,*/
  /* Button, */ Grid,
  InputAdornment,
  List,
  ListItem,
  TextField,
} from "@mui/material";
import {
  ScrollMenu /* VisibilityContext */,
} from "react-horizontal-scrolling-menu";
/* import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone"; */
//import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
//import ReactSelect from "react-select";

import { sidebarListItems } from "../../../components/PageLayout/constants";
import { formatCategoryName } from "../../../utils/helper";
import useStyles from "./ViewAll.styles";
const NewGameHeader = ({
  subCategoryFiltered,
  handleChangeCategory,
  resetAll,
  searchInput,
  handleChangeSearch,
  category,
}) => {
  //  const {
  //     isLoggedIn,
  //   } = useSelector((state) => state.authentication)
  //const navigate = useNavigate();
  const classes = useStyles();
  const { darkMode } = useSelector((state) => state.themeSettings);
  // const params = useParams();
  // const { category } = params;
  //const options = [{ value: 0, label: "All" }];
  // const LeftButton = () => {
  //     const { scrollPrev } = React.useContext(VisibilityContext);
  //     return (
  //       <Button className={ classes.leftArrow} onClick={() => scrollPrev()}>
  //         <KeyboardArrowLeftTwoToneIcon />
  //       </Button>
  //     );
  //   };

  //   const RightButton = () => {
  //     const { scrollNext } = React.useContext(VisibilityContext);
  //     return (
  //       <Button className={ classes.rightArrow } onClick={() => scrollNext()}>
  //         <NavigateNextTwoToneIcon />
  //       </Button>
  //     );
  //   };
  return (
    <div>
      <Grid
        className={`position-relative allGameList ${classes.gameList} reactSeletContent allgameListSlider`}
      >
        <List>
          <ScrollMenu
            // LeftArrow={true && LeftButton}
            // RightArrow={true && RightButton}
            wrapperClassName="horizontalScrollWrapper"
          >
            <ListItem
              key={"val"}
              // onClick={() => {
              //   localStorage.removeItem("backGame");
              //   localStorage.removeItem("backGameValue");
              //   // offset.current = 1;
              //   navigate("/");
              // }}
              onClick={() => {
                localStorage.removeItem("backGame");
                localStorage.removeItem("backGameValue");
                handleChangeCategory({ value: "lobby", label: "Lobby" });
              }}
              className={
                category?.value === "lobby" || (!category?.value && !category)
                  ? classes.active
                  : ""
              }
            >
              <img
                src={"/images/All Games.svg"}
                alt="Casino icon"
                style={{ width: "20px" }}
              />
              <span>Lobby</span>
            </ListItem>
            <ListItem
              key={"val"}
              onClick={() => {
                localStorage.removeItem("backGame");
                localStorage.removeItem("backGameValue");
                // offset.current = 1;
                // navigate("/recents");
                handleChangeCategory({
                  value: "Recent Played",
                  label: "Recent Played",
                });
              }}
              className={
                category?.value === "Recent Played" ? classes.active : ""
              }
            >
              <img
                src={"/images/recent_played.svg"}
                alt="Casino icon"
                style={{ width: "20px" }}
              />
              <span>Recent Played</span>
            </ListItem>

            {subCategoryFiltered?.length > 0 &&
              subCategoryFiltered.map((item, val) => {
                const inde = (val + 2) % 7;
                return (
                  <ListItem
                    key={val}
                    onClick={() =>
                      handleChangeCategory({
                        value: item.tenantGameSubCategoryId,
                        label: item.name,
                      })
                    }
                    className={
                      item.tenantGameSubCategoryId ===
                      Number(category?.value || 0)
                        ? classes.active
                        : ""
                    }
                  >
                    {item.iconName ? (
                      <img
                        src={item?.iconName}
                        alt="Casino icon"
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      <img
                        src={
                          darkMode
                            ? sidebarListItems?.[inde + 2]?.listIconDark
                            : sidebarListItems?.[inde + 2]?.listIconLight
                        }
                        alt="Casino icon"
                      />
                    )}
                    {item.id}
                    <span>{formatCategoryName(item.name)}</span>
                  </ListItem>
                );
              })}
          </ScrollMenu>
        </List>
        {/* {isMobile ? (
            <ReactSelect
              className="basic-single"
              classNamePrefix="select"
              value={category}
              onChange={handleChangeCategoryMobile}
              closeMenuOnSelect={true}
              isSearchable={false}
              name="color"
              placeholder="All Games"
              disabled={!(subCategory?.length > 0)}
              options={[
                ...subCategoryOptions,
              ]}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100% ", 
                }),
                menu: (provided) => ({
                  ...provided,
                  borderRadius: "8px",
                  marginTop: "-1px",
                  width: "100%",
                }),
                option: (provided, state) => ({
                  ...provided,
                  borderRadius: "8px", // Set the radius for the items
                  width: "100%",
                  background: state.isSelected ? "#24242b" : "transparent",
                  color: state.isSelected ? "#fff" : "#fff",
                  "&:hover": {
                    backgroundColor: "#24242b",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: darkMode ? "#fff" : "#24242b",
                  borderRadius: "8px",
                }),
                menuList: (s) => ({
                  ...s,
                  background: "#24242b",
                  borderRadius: "12px",
                  fontSize: "14px",
                  border: "1.4px solid rgba(255, 255, 255, 0.04)",
                  boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: "8px",
                border: "1.4px solid rgba(255, 255, 255, 0.04)",
                colors: {
                  ...theme.colors,
                  neutral0: darkMode ? "#2B2B38" : "#f7df94",
                  primary25: "rgba(123, 98, 218, 0.16)",
                  neutral80: "#fff",
                },
              })}
            />
          ) : (
            <List>
              <ScrollMenu
                LeftArrow={true && LeftButton}
                RightArrow={true && RightButton}
                wrapperClassName="horizontalScrollWrapper"
              >
                <ListItem
                  key={"val"}
                  onClick={() => {
                    localStorage.removeItem("backGame");
                    localStorage.removeItem("backGameValue");
                    offset.current = 1;
                    navigate("/all-games");
                  }}
                  className={isAllgame ? classes.active : ""}
                >
                  <img
                    src={"/images/All Games.svg"}
                    alt="Casino icon"
                    style={{ width: "20px" }}
                  />
                  <span>All Games</span>
                </ListItem>
                <ListItem
                  key={"val"}
                  onClick={() => {
                    localStorage.removeItem("backGame");
                    localStorage.removeItem("backGameValue");
                    offset.current = 1;
                    navigate("/recents");
                  }}
                  className={pathname === "/recents" ? classes.active : ""}
                >
                  <img
                    src={"/images/recent_played.svg"}
                    alt="Casino icon"
                    style={{ width: "20px" }}
                  />
                  <span>Recent Played</span>
                </ListItem>
                {subCategoryFiltered?.length > 0 &&
                  subCategoryFiltered.map((item, val) => {
                    const inde = (val + 2) % 7;
                    return (
                      <ListItem
                        key={val}
                        onClick={() =>
                          handleChangeCategory({
                            catId: item.tenantGameSubCategoryId,
                            name: item.name,
                          })
                        }
                        className={
                          item.tenantGameSubCategoryId === selectedCategory
                            ? classes.active
                            : ""
                        }
                      >
                        {item.iconName ? (
                          <img
                            src={item?.iconName}
                            alt="Casino icon"
                            style={{ width: "20px", height: "20px" }}
                          />
                        ) : (
                          <img
                            src={
                              darkMode
                                ? sidebarListItems?.[inde + 2]?.listIconDark
                                : sidebarListItems?.[inde + 2]?.listIconLight
                            }
                            alt="Casino icon"
                          />
                        )}
                        {item.id}
                        <span>{formatCategoryName(item.name)}</span>
                      </ListItem>
                    );
                  })}
              </ScrollMenu>
            </List>
          )} */}
      </Grid>
      <Grid className={classes.innerHeading}>
        <Grid className={classes.innerHeadingLeft}>
          {/* <Grid className={classes.filterSection}>
            <ReactSelect
              className="basic-single"
              classNamePrefix="select"
              value={selectedSort}
              onChange={handleChangeSort}
              closeMenuOnSelect={true}
              isSearchable={false}
              placeholder="All"
              name="color"
              options={options}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100% ",
                }),
                menu: (provided) => ({
                  ...provided,
                  borderRadius: "8px",
                  marginTop: "-1px",
                  width: "100%",
                }),
                option: (provided, state) => ({
                  ...provided,
                  borderRadius: "8px",
                  width: "100%",
                  background: state.isSelected ? "#24242b" : "transparent",
                  color: state.isSelected ? "#fff" : "#fff",
                  "&:hover": {
                    backgroundColor: "#24242b",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: darkMode ? "#fff" : "#24242b",
                  borderRadius: "8px",
                }),
                menuList: (s) => ({
                  ...s,
                  background: "#24242b",
                  borderRadius: "12px",
                  fontSize: "14px",
                  border: "1.4px solid rgba(255, 255, 255, 0.04)",
                  boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: "8px",
                colors: {
                  ...theme.colors,
                  neutral0: darkMode ? "#2B2B38" : "#f7df94",
                  primary25: "rgba(123, 98, 218, 0.16)",
                  neutral80: "#fff",
                },
              })}
            />
            <Box className={`${classes.filterText} ${classes.sortText}`}>
              <img src="/images/sort.svg" alt="sort" />
              <span>Sort by</span>
            </Box>
          </Grid> */}

          {/* <Grid className={`${classes.filterSection} filterSectionSort`}>
            <ReactSelect
                className="basic-single"
                classNamePrefix="select"
                defaultValue={
                  localStorage.getItem("backGameValue") &&
                  JSON.parse(localStorage.getItem("backGameValue"))
                }
                value={
                  (localStorage.getItem("backGameValue") &&
                    JSON.parse(localStorage.getItem("backGameValue"))) ||
                  selectedProvider
                }
                onChange={(e) => {
                  handleChangeProvider(e);
                }}
                closeMenuOnSelect={true}
                isSearchable={false}
                name="color"
                placeholder="Providers"
                options={providerList}
                maxMenuHeight={150}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: "8px",
                    marginTop: "-1px",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    borderRadius: "8px", // Set the radius for the items
                    background: state.isSelected ? "#24242b" : "transparent",
                    color: state.isSelected ? "#fff" : "#fff",
                    "&:hover": {
                      backgroundColor: "#24242b",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: darkMode ? "#fff" : "#24242b",
                    borderRadius: "8px",
                  }),
                  menuList: (s) => ({
                    ...s,
                    background: "#24242b",
                    borderRadius: "12px",
                    fontSize: "14px",
                    border: "1.4px solid rgba(255, 255, 255, 0.04)",
                    boxShadow:
                      "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "8px",
                  colors: {
                    ...theme.colors,
                    neutral0: darkMode ? "#2B2B38" : "#f7df94",
                    primary25: "rgba(123, 98, 218, 0.16)",
                    neutral80: "#fff",
                  },
                })}
              />

            <Box className={classes.filterText}>
              <img src="/images/filter.svg" alt="filter" />
              <span>Filter by</span>
            </Box>
          </Grid> */}
        </Grid>

        <Grid className={classes.resetSearch}>
          <Grid className={classes.homeFilter}>
            <TextField
              autoComplete="off"
              autoCorrect="off"
              id="search"
              className={classes.searchInput}
              type="text"
              variant="outlined"
              value={searchInput}
              onChange={handleChangeSearch}
              placeholder="Search For Game"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src="/images/search.svg" alt="search" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* <Button className={classes.resetBtn} onClick={resetAll}>
            Reset
          </Button> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default NewGameHeader;
